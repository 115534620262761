<template>
  <viewcard--c
    :title="project"
    :title2="event"
    :btback="{}"
    :busy="!project && !event"
  >
    <b-row class="mb-1 mb-sm-0">
      <b-col md="8">
        <button--c
          :variant="'info'"
          :iconsize="'20'"
          :icon="null"
          :permission="'permission.sub.events.create'"
          :title="'Cadastrar SubEvento'"
          @clicked="onClickSave(null)"
          class="mb-2"
        />
      </b-col>
      <b-col md="4">
        <b-input-group>
          <b-form-input
            placeholder="Pesquise por Nome..."
            autocomplete="off"
            v-model="search"
            @keyup.enter="filter"
          />
          <b-input-group-append>
            <b-button variant="info" @click="filter">
              <feather-icon icon="SearchIcon" class="mr-50" />
              <span>Pesquisar</span>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-overlay :variant="skin" :show="isloading" spinner-variant="primary" spinner-type="grow">
      <Table
        :fields="fields"
        :list="!isloading && list[currentePage] ? list[currentePage].itens : []"
        @orderBy="getRecordsOrderBy"
        border="full"
        responsive
      >
        <template #subEventsTeachers="data">
          <div
            style="white-space: pre-wrap"
            v-if="data.item.subEventsTeachers.length > 0"
          >
            <b-badge
              v-for="(teacher, index) in data.item.subEventsTeachers"
              :key="index"
              :variant="teacher.teacher_res ? 'light-info' : 'light-dark'"
              v-b-tooltip.hover.right="teacher.email"
              :class="[
                data.item.subEventsTeachers.length > 1 &&
                index != data.item.subEventsTeachers.length - 1
                  ? 'm-25'
                  : 'm-0',
              ]"
            >
              {{ teacher.name }}
            </b-badge>
          </div>
          <div v-else>--</div>
        </template>
        <template #name="data">
          <div
            :data-text="data.item.name"
            class="text-truncate ellipsis"
            style="max-width: 600px"
          >
            {{ data.item.name }}
          </div>
        </template>
        <template #actions="data">
          <div class="text-nowrap">
            <!-- <feather-icon
                :id="`details-event-row-${data.item.id}`"
                icon="TrelloIcon"
                size="16"
                class="mx-1"
                @click="onClickSave(data.item)"
              />
              <b-tooltip
                triggers="hover"
                :target="`details-event-row-${data.item.id}`"
                title="Detalhar Evento"
                :delay="{ show: 100, hide: 50 }"
                v-if="data.item.index > 0"
              /> -->
            <feather-icon
              :id="`edit-event-row-${data.item.id}`"
              icon="EditIcon"
              size="16"
              class="mx-1"
              @click="onClickSave(data.item)"
            />
            <b-tooltip
              triggers="hover"
              :target="`edit-event-row-${data.item.id}`"
              title="Editar Evento"
              :delay="{ show: 100, hide: 50 }"
              v-if="data.item.index > 0"
            />
          </div>
        </template>
      </Table>
    </b-overlay>
    <b-pagination
      v-model="currentePage"
      @change="getLoadMore"
      :total-rows="rows"
      v-if="rows > 1"
      first-number
      last-number
      align="center"
      prev-class="prev-item"
      next-class="next-item"
      class="mt-1"
    >
      <template #prev-text>
        <feather-icon icon="ChevronLeftIcon" size="18" />
      </template>
      <template #next-text>
        <feather-icon icon="ChevronRightIcon" size="18" />
      </template>
    </b-pagination>
    <!-- DETALHES DO SUBEVENTO -->
    <b-sidebar
      sidebar-class="sidebar-lg"
      bg-variant="white"
      v-model="isSave"
      right
      backdrop
      shadow
      no-header
    >
      <template #default="{ hide }" v-if="isSave">
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 v-if="dto.id" class="mb-0">Editar SubEvento #{{ dto.id }}</h5>
          <h5 v-else class="mb-0">Cadastrar SubEvento</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <project-subevent-form @result="onResult" :dto="dto" />
      </template>
    </b-sidebar>
  </viewcard--c>
</template>

<script>
import { VBToggle, BBadge, VBTooltip } from "bootstrap-vue";
import Table from "@/components/Table.vue";
import _teachersService from "@/services/teachers-service";
import _guestsService from "@/services/guests-service";
import _subeventService from "@/services/subevent-service";
import project_subevent from "./project-subevent";
import _projectsService from "@/services/projects-service";
import _eventService from "@/services/event-service";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  components: {
    BBadge,
    "project-subevent-form": project_subevent,
    Table,
  },
  data() {
    return {
      project: null,
      event: null,
      isloading: false,
      currentePage: 1,
      search: null,
      size: this.$utils.paginationSize(),
      rows: 1,
      fields: [
        { key: "name", label: "Nome", orderBy: { type: "back" } },
        {
          key: "subEventsTeachers",
          label: "Professor",
        },
        { key: "actions", label: "Ações" },
      ],
      list: [
        {
          page: 0,
          itens: [],
        },
      ],
      orderByParams: {
        search: "",
        ascOrDes: true,
        orderByKey: "",
      },
      isSave: false,
      dto: null,
    };
  },
  created() {
    this.getRecords(this.currentePage);
    this.getProject();
    this.getEvent();
  },
  methods: {
    getProject() {
      if (this.$route.params.projectId > 0) {
        _projectsService
          .find(this.$route.params.projectId)
          .then((res) => {
            if (res.content) {
              this.project = res.content.name;
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error));
      }
    },
    getEvent() {
      if (this.$route.params.id > 0) {
        this.isloading = true;
        _eventService
          .find(this.$route.params.id)
          .then((res) => {
            if (res.content) {
              this.event = res.content.name;
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error));
      }
    },
    getRecords(_page, isOrder = false) {
      this.isloading = true;
      _subeventService
        .show(_page, this.$route.params.id, this.orderByParams)
        .then((res) => {
          if (res.content) {
            this.list.push({ page: _page, itens: res.content });
            if (res.content.length > 0) {
              if (isOrder) return;
              this.rows += res.content.length;
              this.currentePage = _page;
            }
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => {
          this.orderList();
          this.isloading = false;
        });
    },
    getLoadMore(_page) {
      if (!this.list.some((s) => s.page === _page)) {
        this.getRecords(_page);
      }
    },
    getRecordsOrderBy(_params) {
      this.orderByParams.ascOrDes = _params.ascOrDes;
      this.orderByParams.orderByKey = _params.orderByKey;
      let oldList = this.list.map((m) => m);
      this.list = [{ page: 0, itens: [] }];
      oldList.forEach((l) => {
        if (l.page < 1) return;
        this.getRecords(l.page, true);
      });
    },
    orderList() {
      this.list.sort((a, b) => {
        if (a.page < b.page) return -1;
        if (a.page > b.page) return 1;
        return 0;
      });
    },
    filter() {
      this.currentePage = 1;
      this.rows = 1;
      this.list = [{ page: 0, itens: [] }];
      this.orderByParams.search = this.search;
      this.getRecords(this.currentePage);
    },
    onClickSave(_record) {
      this.dto = {
        event_id: _record ? _record.event.id : this.$route.params.id,
        id: _record ? _record.id : 0,
      };
      this.isSave = true;
    },
    onResult(_res) {
      this.isSave = false;
      this.dto = null;
      this.filter();
    },
  },
};
</script>
<style scoped>
.ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s linear;
  padding: 0.5rem 0rem;
  font-size: 12px;
}
.ellipsis:focus,
.ellipsis:hover {
  color: transparent;
}
.ellipsis:focus:after,
.ellipsis:hover:after {
  content: attr(data-text);
  overflow: visible;
  text-overflow: inherit;
  background: #fff;
  position: absolute;
  left: auto;
  top: auto;
  width: auto;
  border: 1px solid #eaebec;
  padding: 0 0.5rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
  white-space: nowrap;
  word-wrap: break-word;
  display: block;
  color: black;
  margin-top: -1.25rem;
  font-size: 12px;
  z-index: 10;
}
</style>